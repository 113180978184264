import React, { useState, useMemo, useCallback, Fragment, useEffect } from 'react'
import { Button, Modal, Grid } from 'semantic-ui-react'
import EmailForm from '../../shared/EmailForm';

const styles = {
  modal: {
    height: '95%'
  }
}

const ButtonSendEmail = ({
  orderId,
  status,
  content,
  customer,
  loading,
  showDialog,
  getMailContent,
  sendMail,
  sendMailRequest,
  cancelMail
}) => {

  const [loadingButton, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [form, setForm] = useState({
    email,
    htmlContent: content
  });

  const setHtmlContent = useCallback((htmlContent) => {
    setForm((state) => ({ ...state, htmlContent }));
  }, [content]);

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setEmailError(false);
    setForm((state) => ({ ...state, email: value }));
  }, [email])

  useEffect(() => {
    setForm({
      email,
      htmlContent: content
    })
  }, [email, content])

  useEffect(() => {
    if (!active) return;
    getMailContent(orderId);
  }, [active, orderId])

  const onClick = useCallback(() => {
    sendMailRequest();
    setActive(true);
  }, []);

  const handleSend = useCallback(() => {
    if (!form.email) return setEmailError(true);
    setLoading(true);
    setActive(false);
    sendMail({ documentId: orderId, ...form });
    setTimeout(handleReset, 1000)
    setLoading(false);
  }, [orderId, form]);

  const handleCancel = useCallback(() => {
    handleReset();
    cancelMail()
  }, []);

  const handleReset = useCallback(() => {
    setActive(false);
    setLoading(false)
  }, []);

  const { shortName, email } = customer;

  const canBeSend = useMemo(() => {
    return status == 'ordered'
  }, [status, email])

  if (!canBeSend) {
    return null;
  }

  return (
    <Fragment>
      <Button
        basic
        loading={loadingButton && loading}
        icon="mail"
        size="small"
        onClick={onClick}
      />
      <Modal
        open={showDialog && active}
        size="large"
        style={styles.modal}
        onClose={handleCancel}
      >
        <Modal.Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width="12">
                E-mail to {shortName}
              </Grid.Column>
              <Grid.Column width="4" textAlign="right">
                <Button onClick={handleCancel} content="Cancel" />
                <Button primary labelPosition="right" icon="send" content='Send' onClick={handleSend} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <EmailForm
            emailError={emailError}
            email={email}
            handleEmailChange={handleEmailChange}
            content={content}
            setHtmlContent={setHtmlContent}
            loading={loading}
          />
        </Modal.Content>
      </Modal>
    </Fragment>

  )

}

export default ButtonSendEmail;
