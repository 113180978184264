import Api from "../../utils/resourcesApi";
import { createAction } from "redux-actions";
import { put, call, takeEvery, select } from "@redux-saga/core/effects";
import { omit, isEmpty } from "lodash";
import * as commonActionHandlers from "utils/redux/commonActionHandlers";
import api from "utils/resources/requisitionLists";

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  SET_LOADING: "@REQUISITION_LISTS/SET_LOADING",
  SET_TOTALS: "@REQUISITION_LISTS/SET_TOTALS",
  SET_QUOTES: "@REQUISITION_LISTS/SET_QUOTES",
  REMOVE_QUOTES: "@REQUISITION_LISTS/REMOVE_QUOTES",
  SET_QUOTE_ITEMS: "@REQUISITION_LISTS/SET_QUOTE_ITEMS",
  GET_QUOTES_REQUEST: "@REQUISITION_LISTS/GET_QUOTES_REQUEST",
  SET_QUOTES_WITH_PRICE: "@REQUISITION_LISTS/SET_QUOTES_WITH_PRICE",
  REMOVE_QUOTE_ITEMS: "@REQUISITION_LISTS/REMOVE_QUOTE_ITEMS",
  SET_ACTIVE_ITEM_ROW: "@REQUISITION_LISTS/SET_ACTIVE_ITEM_ROW",
  SET_ACTIVE_QUOTE_ROW: "@REQUISITION_LISTS/SET_ACTIVE_QUOTE_ROW",
  CLEAR_ACTIVE_ITEM_ROW: "@REQUISITION_LISTS/CLEAR_ACTIVE_ITEM_ROW",
  CLEAR_ACTIVE_QUOTE_ROW: "@REQUISITION_LISTS/CLEAR_ACTIVE_QUOTE_ROW",
  SET_STATUS_FILTER: "@REQUISITION_LISTS/SET_STATUS_FILTER",
  CREATE_REQUEST: "@REQUISITION_LISTS/CREATE_REQUEST",
  CREATE_REQUEST_SUCCESS: "@REQUISITION_LISTS/CREATE_REQUEST_SUCCESS",
  CREATE_REQUEST_FAILED: "@REQUISITION_LISTS/CREATE_REQUEST_FAILED",
  LOAD_WITH_DEFAULT_FILTER: "@REQUISITION_LISTS/LOAD_WITH_DEFAULT_FILTER",
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  setLoading: createAction(constants.SET_LOADING),
  setTotals: createAction(constants.SET_TOTALS),
  getQuotes: createAction(constants.GET_QUOTES_REQUEST),
  setQuotes: createAction(constants.SET_QUOTES),
  removeQuotes: createAction(constants.REMOVE_QUOTES),
  setActiveItemRow: createAction(constants.SET_ACTIVE_ITEM_ROW),
  setActiveQuoteRow: createAction(constants.SET_ACTIVE_QUOTE_ROW),
  setQuoteItems: createAction(constants.SET_QUOTE_ITEMS),
  setQuotesWithPrice: createAction(constants.SET_QUOTES_WITH_PRICE),
  removeQuoteItems: createAction(constants.REMOVE_QUOTE_ITEMS),
  clearActiveItemRow: createAction(constants.CLEAR_ACTIVE_ITEM_ROW),
  clearActiveQuoteRow: createAction(constants.CLEAR_ACTIVE_QUOTE_ROW),
  setStatusFilter: createAction(constants.SET_STATUS_FILTER),
  createRequest: createAction(constants.CREATE_REQUEST),
  createRequestSuccess: createAction(constants.CREATE_REQUEST_SUCCESS),
  createRequestFailed: createAction(constants.CREATE_REQUEST_FAILED),
  loadWithDefaultFilter: createAction(constants.LOAD_WITH_DEFAULT_FILTER),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  loading: false,
  search: localStorage.getItem("@REQUISITION_LISTS/CHANGE_SEARCH") || "",
  totals: {
    total: 0,
    paid: 0,
    unpaid: 0,
  },
  quotes: [],
  activeQuoteRow: {},
  activeItemRow: {},
  quoteItems: [],
  quotesWithPrice: [],
  statusFilter: {
    type: "select",
    value: ["open", "requested", "offered", "ordered", "in_transit"],
  },
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.CHANGE_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, action.payload);
    return commonActionHandlers.changeSearch(state, action);
  },
  [constants.CLEAR_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, "");
    return commonActionHandlers.clearSearch(state, action);
  },
  [constants.SET_TOTALS]: (state, { payload }) => ({
    ...state,
    totals: payload,
  }),
  [constants.SET_LOADING]: (state, { payload }) => ({
    ...state,
    loading: payload,
  }),
  [constants.SET_QUOTES]: (state, { payload }) => ({
    ...state,
    quotes: { ...state.quotes, ...payload },
  }),
  [constants.REMOVE_QUOTES]: (state, { payload }) => ({
    ...state,
    quotes: omit(state.quotes, payload),
  }),
  [constants.SET_QUOTE_ITEMS]: (state, { payload }) => ({
    ...state,
    quoteItems: { ...state.quoteItems, ...payload },
  }),
  [constants.SET_QUOTES_WITH_PRICE]: (state, { payload }) => ({
    ...state,
    quotesWithPrice: { ...state.quotesWithPrice, ...payload },
  }),
  [constants.REMOVE_QUOTE_ITEMS]: (state, { payload }) => ({
    ...state,
    quoteItems: omit(state.quoteItems, payload),
  }),
  [constants.SET_ACTIVE_ITEM_ROW]: (state, { payload }) => ({
    ...state,
    activeItemRow: { ...state.activeItemRow, ...payload },
  }),
  [constants.SET_ACTIVE_QUOTE_ROW]: (state, { payload }) => ({
    ...state,
    activeQuoteRow: { ...state.activeQuoteRow, ...payload },
  }),
  [constants.CLEAR_ACTIVE_ITEM_ROW]: (state, { payload }) => ({
    ...state,
    activeItemRow: omit(state.activeItemRow, payload),
  }),
  [constants.CLEAR_ACTIVE_QUOTE_ROW]: (state, { payload }) => ({
    ...state,
    activeQuoteRow: omit(state.activeQuoteRow, payload),
  }),
  [constants.SET_STATUS_FILTER]: (state, { payload }) => ({
    ...state,
    statusFilter: payload,
  }),
  [constants.CREATE_REQUEST_FAILED]: (state, { payload }) => ({
    ...state,
    errors: payload,
  }),
});

const newSagas = (baseSagas, constants, actions) => {
  function* applyStatusFilter({ payload: { type, value } }) {
    try {
      const statuses = type === "all" ? "all" : value.join(",");

      yield put(actions.changeFilter("status", statuses));
      yield put(actions.applyFilter());
    } catch (e) {
      console.error(e);
      yield put(actions.setErrors(e));
    }
  }

  function* getQuotes({ payload: { requisitionListId, cb } }) {
    try {
      const attributes = {
        filter: {
          requisition_list_id: requisitionListId,
        },
      };

      const { data } = yield call(Api.quotations.index, attributes);
      yield put(actions.setQuotes({ [requisitionListId]: data.data }));

      if (cb) yield call(cb);
    } catch (e) {
      console.error(e);
      yield put(actions.setErrors(e));
    }
  }

  // function* setTotals({ payload }) {
  //   try {
  //     const totals = get(payload, 'data.meta.totals');
  //     yield put(actions.setTotals(totals));
  //   } catch (e) {
  //     console.error(e);
  //     yield put(actions.setErrors(e));
  //   }
  // }

  function* createRequest({ payload: { cb, ...payload } }) {
    try {
      const response = yield call(api.createRequest, payload);
      yield put(actions.createRequestSuccess(response.data));
      if (cb) yield call(cb);
      yield put(actions.loadItemsRequest());
    } catch (e) {
      console.error(e);
      yield put(actions.createRequestFailed(e));
    }
  }

  function* requestWithDefaultFilter() {
    try {
      const filter = yield select((state) => state.requisitionLists?.filter);
      if (isEmpty(filter)) {
        const statusFilter = yield select(
          (state) => state.requisitionLists.statusFilter.value
        );
        const statuses = statusFilter.join(",");

        yield put(actions.changeFilter("status", statuses));
        yield put(actions.applyFilter());
      } else {
        yield put(actions.loadItemsRequest());
      }
    } catch (e) {
      console.error(e);
      yield put(actions.setErrors(e));
    }
  }

  return [
    takeEvery(constants.SET_STATUS_FILTER, applyStatusFilter),
    takeEvery(constants.GET_QUOTES_REQUEST, getQuotes),
    takeEvery(constants.LOAD_WITH_DEFAULT_FILTER, requestWithDefaultFilter),
    takeEvery(constants.CREATE_REQUEST, createRequest),
  ];
};

const extendedSagas = (baseSagas, newSagas) => {
  return () => [...baseSagas(), ...newSagas];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  filter: state.requisitionLists.filter,
  totals: state.requisitionLists.totals,
  quotes: state.requisitionLists.quotes,
  quoteItems: state.requisitionLists.quoteItems,
  quotesWithPrice: state.requisitionLists.quotesWithPrice,
  activeItemRow: state.requisitionLists.activeItemRow,
  activeQuoteRow: state.requisitionLists.activeQuoteRow,
  statusFilter: state.requisitionLists.statusFilter,
});

export default {
  extendedConstants,
  extendedActions,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors,
  extendedInitialState,
};
