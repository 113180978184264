import {useLocalStorage} from "components/App/LocalStorageContext";
import {TENDER_SELECTED_KEY} from "../requests/utils";
import {useCallback} from "react";


export const clearSelectedRLTenders = () => {
  localStorage.removeItem(TENDER_SELECTED_KEY);
}
export const useSelectedRLTenders = () => {

  const [selectedTender, setSelectedTender] = useLocalStorage(TENDER_SELECTED_KEY, []);

  const addToTender = useCallback(({id, attributes}) => {
    setSelectedTender([...selectedTender, {id, attributes}]);
  }, [selectedTender, setSelectedTender]);

  const removeFromTender = useCallback((id) => {
    setSelectedTender(selectedTender.filter((item) => item.id !== id));
  }, [selectedTender, setSelectedTender]);

  const clearSelected = useCallback(() => {
    setSelectedTender([]);
  }, [setSelectedTender]);

  return {selectedTender, addToTender, removeFromTender, clearSelected};
}
