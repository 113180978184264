import find from 'lodash/find';

/**
 * convert json-api response
 * add all *included* items to body
 * @param result - {Object} - api response
 * @returns {Object}
 */
export const piItemsToHeader = (result) => {
  if (!result.data.data.relationships) return result;

  const instructionInvoices = [];
  result.data.data.relationships.instruction_invoices.data.forEach(item =>{
    const incItem = find(result.data.included, item);
    instructionInvoices.push(incItem);
  });
  result.data.data['instruction_invoices'] = instructionInvoices;
  return result;
};

export const poItemsToHeader = (result) => {
  if (!result.data.data.relationships) return result;

  const poItems = [];
  result.data.data.relationships.items.data.forEach(item =>{
    const incItem = find(result.data.included, item);
    poItems.push(incItem);
  });
  result.data.data['items'] = poItems;
  return result;
};
