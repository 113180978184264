import * as api from '../api/adapters/json_api';


const type = 'api_resources/ac_budget_items';

export default {
  index: (pagination, sort, id) => api.read({ type, id }, {
    query: { sort: 'item'}
  }),
  roots: () => api.read({type}, {
    query: {filter: {roots: true}, sort: 'item'}
  })
}
