import { call, put, takeEvery, select, debounce } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import { stopSubmit } from 'redux-form';
import {changePage} from "./commonActionHandlers";
import { notifyUpdate, notifyCreate } from 'utils/toastNotify';
import { pathsByResource } from "utils/paths";
import { LOCATION_CHANGE, push, getLocation } from 'connected-react-router'
import get from "lodash/get";


/**
 * getCRUDSagas
 * @param {String} basePageUrl
 * @param {Object} api
 * @param {Object} actions
 * @param {Object} constants
 * @param {String} statePropertyName
 * @param {String} formName
 * @returns {function(): any[]}
 */
export default function getCRUDSagas(basePageUrl, api, actions, constants, statePropertyName, formName) {
  function* fetchItems() {
    try {
      const { pagination, sort, search, filter } = yield select(s => s[statePropertyName]);
      const settings = yield select(s => s.settings);

      const data = yield call(api.index, pagination, sort, search, filter, settings);

      const pageCount = data.data.meta.page_count;

      if (pageCount > 0 && pagination.activePage > pageCount) {
        yield put(actions.changePageNumber(pageCount));
      }
      yield put(actions.addItems(data));
    } catch (e) {
      console.error(e);

      yield putErrorsToState({ payload: e });
    }
  }

  function* fetchItem({ payload }) {
    try {
      yield put(actions.addLoadedItem({data: { data: {} }}));
      yield put(actions.updateItemError(undefined));

      const data = yield call(api.show, payload);

      yield put(actions.addLoadedItem(data));
    } catch (e) {
      console.error(e);

      yield putErrorsToState({ payload: e });
    }
  }

  function* fetchAfterSearch() {
    yield put(actions.applySearch()); // reset current pagination page
    yield put(actions.loadItemsRequest());
  }

  function* createItem({ payload: { cb, skipFetch = false, stayFormOpen = false, ...payload } }) {
    try {
      const { data: { data } } = yield call(api.create, payload);
      if (!skipFetch) yield call(fetchItems);
      if (cb) yield call(cb);
      if (stayFormOpen) {
        yield put(push(pathsByResource(basePageUrl).edit(data.id)));
        yield put(actions.loadItemRequest(data.id));
      } else {
        yield put(push(pathsByResource(basePageUrl).index()));
      }
      yield call(notifyCreate, data);
    } catch (e) {
      console.error(e);

      yield putErrorsToState({ payload: e });

      if (e.errorByField) yield put(stopSubmit(formName, e.errorByField));
    }
  }

  function* updateItem({ payload: { cb, skipFetch = false, stayFormOpen = false, ...payload } }) {
    try {
      const { pathname } = yield select(getLocation);

      let id = undefined;
      id = get(matchPath(pathname, { path: `${basePageUrl}/:id/edit` }), 'params.id');
      if (!id) id = get(matchPath(pathname, { path: `${basePageUrl}/:id` }), 'params.id');



      const { data: { data } } = yield call(api.update, id, payload);
      if (!skipFetch) yield call(fetchItems);
      if (cb) yield call(cb);
      if (stayFormOpen) {
        yield put(actions.loadItemRequest(id));
      } else {
        yield put(push(pathsByResource(basePageUrl).index()));
      }
      yield call(notifyUpdate, data);
    } catch (e) {
      console.error(e);

      yield putErrorsToState({ payload: e });

      if (e.errorByField) yield put(stopSubmit(formName, e.errorByField));
    }
  }

  function* deleteItem({ payload }) {
    try {
      yield call(api.delete, payload);
      yield call(fetchItems);
    } catch (e) {
      console.error(e);

      putErrorsToState({ payload: e });
    }
  }

  function* fetchItemsPageInit({ payload: { action, location: { pathname } } }) {
    const didOpenPage = (
      (pathname === '/' || matchPath(pathname, { path: `${basePageUrl}` }))
      && action === 'POP'
    );

    if (didOpenPage) yield put(actions.loadItemsRequest());
  }

  function* putErrorsToState({ payload: e }) {
    let errors = e;
    get(e, 'response.data.errors') && (errors = get(e, 'response.data.errors'));
    get(e, 'response.data.error') && (errors = [get(e, 'response.data')]);

    yield put(actions.loadItemError(errors));
  }

  /**
  * getFeatureSagas (rename)
  * each saga file should return all feature sagas
  * @returns {ForkEffect[]}
  */
  function getFeatureSagas() {
    return [
      takeEvery([
        constants.SORT_ITEMS,
        constants.APPLY_FILTER,
        constants.CHANGE_PAGE_NUMBER,
        constants.CHANGE_PAGE_ITEMS_COUNT,
        constants.LOAD_ITEMS_REQUEST,
      ], fetchItems),
      takeEvery(constants.LOAD_ITEM_REQUEST, fetchItem),
      takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
      takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
      takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
      debounce(700, constants.CHANGE_SEARCH, fetchAfterSearch),
      takeEvery(constants.CLEAR_SEARCH, fetchAfterSearch),
      // takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
    ];
  }

  getFeatureSagas.fetchItems = fetchItems;
  getFeatureSagas.fetchAfterSearch = fetchAfterSearch;
  getFeatureSagas.fetchItem = fetchItem;
  getFeatureSagas.createItem = createItem;
  getFeatureSagas.updateItem = updateItem;
  getFeatureSagas.deleteItem = deleteItem;
  getFeatureSagas.fetchItemsPageInit = fetchItemsPageInit;
  getFeatureSagas.getFeatureSagas = getFeatureSagas;

  return getFeatureSagas;
}
