import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { toast } from 'react-semantic-toasts';
import { actions, constants } from './index';
import Api from 'utils/resources/purchaseOrders';

export function* sendEmail({ payload }) {
  try {
    const { documentId, email, htmlContent: content } = payload;

    yield call(Api.poMailSend, documentId, { email, content })
    yield put(actions.sendMailSuccess());
    yield call(toast, { type: 'success', title: 'E-mail sent successfully!' })
  } catch (error) {
    yield put(actions.setErrors(error));
    yield call(toast, { type: 'error', title: 'Something went wrong!', description: error.message })
  }

};

export function* getMailContent({ payload: orderId }) {
  try {
    const { data: { content } } = yield call(Api.poMailContent, orderId)
    yield put(actions.setMailContent(content));
  } catch (error) {
    yield put(actions.setErrors(error));
    yield call(toast, { type: 'error', title: 'Something went wrong!', description: error.message })
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.SEND_MAIL
    ], sendEmail),
    takeEvery([
      constants.GET_MAIL_CONTENT
    ], getMailContent),

  ];
}
