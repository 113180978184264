import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { Grid, Loader, Segment } from "semantic-ui-react";

import { BrowserRouter, Route } from "react-router-dom";

import titleFromUrl from "utils/titleFromUrl";

import DeleteDocumentConfirm from "features/components/related-documents/delete/component/Confirm.jsx";

import "./layout.scss";

import { CurrentUser } from "utils/user.js";

import {
  InvoicesPeriodSelect,
  POPeriodSelect,
  PIPeriodSelect,
} from "./PeriodTypeSelect";

const Profile = lazy(() => import("./Profile"));
const Header = lazy(() => import("./Header"));

const Totals = lazy(() => import("./Totals"));
const POTotals = lazy(() =>
  import("features/components/totals/purchase_orders")
);
const PITotals = lazy(() =>
  import("features/components/totals/payment_instructions")
);
const InvTotals = lazy(() => import("features/components/totals/invoices"));
const RLTotals = lazy(() =>
  import("features/components/totals/requisition_lists")
);
const PSTotals = lazy(() =>
  import("features/components/totals/partner_schemes")
);

const Navigation = lazy(() => import("./Navigation"));
const CompanySelect = lazy(() => import("./CompanySelect"));

const ToolBarActionsPI = lazy(() =>
  import("features/payment-instructions/component/ToolBarActions")
);
const ToolBarActionsRL = lazy(() =>
  import("features/requisition-lists/component/ToolBarActions")
);
const ToolBarActionsDN = lazy(() =>
  import("features/delivery-notes/component/ToolBarActions")
);
const ToolBarActionsInv = lazy(() =>
  import("features/invoices/component/ToolBarActions")
);
const ToolBarActionsPayroll = lazy(() =>
  import("features/payrolls/component/ToolBarActions")
);
const ToolBarActionsCompanies = lazy(() =>
  import("features/companies/component/ToolBarActions")
);

const CompanyPayrollsSelect = lazy(() =>
  import("features/payrolls/component/CompanySelect")
);
const CompanyPIMultiSelect = lazy(() =>
  import("features/payment-instructions/component/CompanyMultiSelect")
);
const CompanyDNMultiSelect = lazy(() =>
  import("features/delivery-notes/component/CompanyMultiSelect")
);
const CompanyPOMultiSelect = lazy(() =>
  import("features/purchase-orders/components/CompanyMultiSelect.jsx")
);
const CompanyInvMultiSelect = lazy(() =>
  import("features/invoices/component/CompanyMultiSelect.jsx")
);
const VesselRlMultiSelect = lazy(() =>
  import("features/requisition-lists/component/FiltersRow/VesselFilter")
);

const FiltersRowRL = lazy(() =>
  import("features/requisition-lists/component/FiltersRow")
);

const currentUser = new CurrentUser();

const TotalComponent = () => (
  <BrowserRouter>
    <div className="app-totals">
      <Route component={Totals} path="/invoices" />
      <Route component={InvTotals} path="/invoices_new" />
      <Route component={RLTotals} path="/requisition_lists" />
      <Route component={POTotals} path="/purchase_orders" />
      <Route component={PITotals} path="/payment_instructions" />
      <Route component={PSTotals} path="/partner_schemes" />
    </div>
  </BrowserRouter>
);

const CompanySelectComponent = () => (
  <BrowserRouter>
    <Route component={CompanySelect} path="/invoices" />
    <Route component={CompanyInvMultiSelect} path="/invoices_new" />
    <Route component={CompanyPayrollsSelect} path="/payrolls" />
    <Route component={CompanyPIMultiSelect} path="/payment_instructions" />
    <Route component={CompanyDNMultiSelect} path="/delivery_notes" />
    <Route component={CompanyPOMultiSelect} path="/purchase_orders" />
    <Route component={VesselRlMultiSelect} path="/requisition_lists" />
  </BrowserRouter>
);

const Layout = (Page) => {
  const PageWithHeader = (props) => (
    <div className="ui container">
      <DeleteDocumentConfirm />
      <Suspense fallback={<Loader active inline="centered" />}>
        <Grid>
          <Grid.Row className="app-header-row">
            <Grid.Column width={4} textAlign="left">
              <Header title={titleFromUrl(props.match.path)} />
            </Grid.Column>
            <Grid.Column width={8} textAlign="center">
              <TotalComponent />
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Profile push={props.history.push} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row floated="right">
                  <Grid.Column
                    id="right-column-select-company"
                    verticalAlign="bottom"
                    textAlign="right"
                  >
                    <CompanySelectComponent />
                    <BrowserRouter>
                      <Route
                        component={InvoicesPeriodSelect}
                        path="/(invoices|invoices_new)"
                      />
                      <Route
                        component={POPeriodSelect}
                        path="/purchase_orders"
                      />
                      <Route
                        component={PIPeriodSelect}
                        path="/payment_instructions"
                      />
                    </BrowserRouter>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="app-filters-row">
              <BrowserRouter>
                <Route component={FiltersRowRL} path="/requisition_lists" />
              </BrowserRouter>
          </Grid.Row>
          <Grid.Row className="app-actions-row">
            <Grid.Column width={4} textAlign="left">
              <Navigation
                path={props.match.path}
                push={props.history.push}
                currentUser={currentUser}
              />
            </Grid.Column>
            <Grid.Column id="table-actions" width={12} textAlign="left">
              <BrowserRouter>
                <Route component={ToolBarActionsCompanies} path="/companies" />
                <Route component={ToolBarActionsPI} path="/payment_instructions" />
                <Route component={ToolBarActionsDN} path="/delivery_notes" />
                <Route component={ToolBarActionsInv} path="/invoices_new" />
                <Route component={ToolBarActionsPayroll} path="/payrolls" />
                <Route component={ToolBarActionsRL} path="/requisition_lists" />
              </BrowserRouter>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <section className="app-content">
          <Page {...props} />
        </section>
      </Suspense>
    </div>
  );

  PageWithHeader.propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func,
      push: PropTypes.func,
    }).isRequired,
  };

  return PageWithHeader;
};

export default Layout;
