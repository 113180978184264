import isEmpty from "lodash/isEmpty";
import * as api from "../api/adapters/json_api";
import get from "lodash/get";

const type = "requisition_lists";
const path = `api_resources/${type}`;

/**
 * sortOptions - Returns the sort options if they are not empty.
 * @param sort - The sort settings.
 * @returns {string} - The sort options.
 */
const sortOptions = (sort) => {
  if (isEmpty(sort.field)) {
    return "-created_at";
  }

  const directionPrefix = sort.direction === "asc" ? "" : "-";
  return `${directionPrefix}${sort.field}`;
};

/**
 * searchOptions - Returns the search query if it is not empty.
 * @param search
 * @returns {null|{search}}
 */
const searchOptions = (search) => (isEmpty(search) ? null : {search});

export default {
  /**
   * getRequisitionList - Retrieves a specific requisition list.
   * @param {string|number} id - The ID of the requisition list to be retrieved.
   * @returns {Promise<*>}
   */
  show: (id) => api.read({type, id}, {}),

  /**
   * Retrieves a list of requisition lists.
   * @param {Object} pagination - The pagination settings.
   * @param {Object} sort - The sort settings.
   * @param {string} search - The search query.
   * @param {Object} filter - The filter settings.
   * @param {Object} settings - The settings.
   * @returns {Promise<*>} - The response from the API.
   */
  index: (pagination, sort, search, filter, settings) => {
    return api.read(
      {type: path},
      {
        query: {
          sort: sortOptions(sort),
          page: {
            size: pagination.itemsPerPage,
            number: pagination.activePage,
          },
          filter: {
            ...filter,
            ...searchOptions(search),
            issue_year: get(settings, "requisitionLists.budgetYear"),
          },
        },
      }
    );
  },

  /**
   * Creates a new request.
   * @param {Object} payload - The data to be sent in the request.
   * @returns {Promise<*>} - The response from the API.
   */
  createRequest: (payload) => api.create(
    {type: path, action: "create_requests"},
    {},
    payload
  ),

  /**
   * Creates a new tender.
   * @param {Object} payload - The data to be sent in the request.
   * @returns {Promise<*>} - The response from the API.
   */
  createTender: (payload) => api.create(
    {type: path, action: "create_tender"},
    {},
    payload
  ),

  /**
   * Creates a new tender item.
   * @param {Object} payload - The data to be sent in the request.
   * @returns {Promise<*>} - The response from the API.
   */
  createTenderItem: (payload) => api.create(
    {type: path, action: "create_tender_item"},
    {},
    payload
  ),

  /**
   * Creates multiple tender items at once.
   * @param {Object} payload - The data to be sent in the request.
   * @returns {Promise<*>} - The response from the API.
   */
  bulkCreateTenderItems: (payload) => api.create(
    {type: path, action: "bulk_create_tender_items"},
    {},
    payload
  ),

  /**
   * Updates a specific tender item.
   * @param {string|number} id - The ID of the tender item to be updated.
   * @param {Object} payload - The data to be sent in the request.
   * @returns {Promise<*>} - The response from the API.
   */
  updateTenderItem: (id, payload) => api.create(
    {type: path, action: "update_tender_item"},
    {},
    {id, ...payload}
  ),

  /**
   * Deletes a specific tender item.
   * @param {string|number} id - The ID of the tender item to be deleted.
   * @returns {Promise} - The response from the API.
   */
  deleteTenderItem: (id) => api.create(
    {type: path, action: "delete_tender_item"},
    {},
    {id}
  ),



  /**
   * Retrieves the tender table for a specific tender.
   * @param {string|number} tenderId - The ID of the tender.
   * @returns {Promise<*>} - The response from the API.
   */
  tenderTable: (tenderId) => api.read(
    {type: path, action: "tender_table"},
    {query: {tender_id: tenderId}}
  ),

  /**
   * Generates a purchase order.
   * @param {Object} payload - The data to be sent in the request.
   * @returns {Promise<*>} - The response from the API.
   */
  generatePO: (payload) => api.create(
    {type: path, action: "generate_po"},
    {},
    payload
  ),

  /**
   * Removes a supplier.
   * @param {Object} payload - The data to be sent in the request.
   * @returns {Promise<*>} - The response from the API.
   */
  removeSupplier: (payload) => api.create(
    {type: path, action: "remove_supplier"},
    {},
    payload
  ),
};
