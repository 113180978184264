import * as api from '../api/adapters/json_api';
import isEmpty from "lodash/isEmpty";
import { poItemsToHeader} from "../converter/show";


const type = 'api_resources/purchase_orders';
const typeItems = 'purchase_orders';

const sortOptions = (sort) => !sort || isEmpty(sort.field) ?
  '-order_date' :
  `${ sort.direction === 'asc' ? '' : '-' }${ sort.field }`;

const indexFields = ["presentation", "description", "order_date", "number", "budget_year", "currency",
  "company_id", "vessel_id", "status", "date_paid", "sum", "sum_usd", "created_at", "updated_at", "created_by_id",
  "port_id", "agent_id", "eta", "etd", "supplied", "message_number", "delivery_description", "approving_status",
  "user_pending_approve", "delivery_string"].join(',');

const showFields = ["presentation", "description", "order_date", "number", "budget_year", "currency",
  "company_id", "vessel_id", "status", "date_paid", "sum", "sum_usd", "created_at", "updated_at", "created_by_id",
  "port_id", "agent_id", "eta", "etd", "supplied", "message_number", "delivery_description", "approving_status",
  "user_pending_approve", "delivery_string", "items", "related_documents", "self_present", "country_id",
  "custom_rate", "custom_rate_currency", "logistic_status",  "date_of_readiness", "weight", "dimensions",
  "estimated_shipping_cost", "awb_number", "warehouse_location", "logistics_company", "warehouse_location_id",
  "logistics_company_id", "on_schedule", "acknowledged_date",
].join(',');


export default {

  totals: (options) => api.read({ type, id: 'totals' }, { query: { ...options } }),

  start_approve: ({ id, rounds }) => api.update({ type, id, action: 'start_approve' }, {}, { rounds }),

  show: (id) => api.read(
    { type, id },
    {
      query: {
        include: 'items',
        fields: { [typeItems]: showFields }
      }
    }
  ).then(poItemsToHeader),

  index: (pagination, sort, search, filter, settings, options = {fields: null}) => api.read(
    { type },
    {
      query: {
        sort: sortOptions(sort),
        page: { size: pagination.itemsPerPage, number: pagination.activePage },
        filter: filter,
        fields: options.fields ||{ [typeItems]: indexFields }
      }
    }
  ),

  create: (attributes) => api.create({ type }, {}, attributes),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  approve_process: (id) => api.read({ type, id, action: 'approve_process' }),

  approve: (id) => api.read({ type, id, action: 'approve' }),

  reject: (id) => api.read({ type, id, action: 'reject' }),

  delete: (id) => api.destroy({ id, type }),

  poMailContent: (id) => api.read({ type, id, action: 'order_mailer_content' }),

  poMailSend: (id, options) => api.update({ type, id, action: 'order_mailer_send_mail' }, {}, options),
}
